import React from 'react'
import './App.css';
import Login from './components/login/login'
import { Helmet } from 'react-helmet';

function App() {

  return (
    <div>
        <Helmet>
          <title>Beginnings</title>
          <meta name="description" content="Beginnings" />
          {/* Add more meta tags as needed */}
        </Helmet>
        <Login/>
    </div>
  );
}

export default App;
