import './login.css';
import React, { useState, useEffect } from 'react';
import ovumiaLogo from '../../assets/ovumia-logo.svg';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const backendUrl = 'https://beginnings.trafore.com'; // Replace with your backend server URL
  const backendPort = '3002'; // Replace with your backend server port
  const dashboardUrl = `${backendUrl}:${backendPort}/dashboard`; // Dashboard URL

  useEffect(() => {
    // This function runs after the component has mounted
    // Any code with side effects can be placed here
  }, []); // Empty dependency array ensures this effect runs only once after mounting

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const loginUrl = `${backendUrl}:${backendPort}/login`;
      console.log('Login URL:', loginUrl);
      console.log('Request Body:', { username, password });
  
      // Send username and password to login endpoint
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      console.log('Login Response:', response);
  
      if (!response.ok) {
        throw new Error('Invalid username or password');
      }
  
      // Extract token from response
      const responseData = await response.json();
      const token = responseData.token;
      console.log('Token:', token);
  
      // Save token to local storage
      localStorage.setItem('token', token);
      console.log("Localstorage token", token)
  
      // Redirect to the dashboard page upon successful login
      window.location.href = `${dashboardUrl}?token=${token}`;
      
      
    } catch (error) {
      console.error('Login Fetch error:', error);
      setError(error.message);
    }
  };
  
  
  
  

  return (
    <div className='joinOuterContainer'>
      <div className='joinInnerContainer'>
        <img src={ovumiaLogo} alt="Ovumia Logo" className='logo' />
        <h1 className='heading'>Login</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              placeholder="Username"
              className='joinInput'
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              className='joinInput mt-20'
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <button className='button mt-20' type="submit">Login</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Login;


